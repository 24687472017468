.app {
  position: relative;
}

/* let's use bootstrap for utility classes next time */
.d-none {
  display: none !important;
}


