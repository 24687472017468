.header {
  display: flex;
  flex-direction: row;
  position: absolute;
  background-color: transparent;
  width: 100%;
  padding-top: 20px;
  z-index: 3;
  /*padding-left: 20px;*/
  /*// Small devices (landscape phones, 576px and up)*/
  /*// Medium devices (tablets, 768px and up)*/
  /*@media (min-width: 768px) {}*/
  /*// Large devices (desktops, 992px and up)*/
  /*@media (min-width: 992px) {}*/
  /*// X-Large devices (large desktops, 1200px and up)*/
  /*@media (min-width: 1200px) {}*/
  /*// XX-Large devices (larger desktops, 1400px and up)*/
  /*@media (min-width: 1400px) {}*/
}

.header-button {
  height: 45px;
  border-radius: 31px;
  border: 1px solid white;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.9rem !important;
  font-family: inherit;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  padding: 10px 25px;
}

.header-left-buttons {
  display: flex;
  flex-direction: row;
}

.header-right-buttons {
  margin-left: auto;
  display: flex;
}

.back-button {
  display: flex;
  align-items: center;
}

.back-button-text {
  margin-left: 10px;
}
