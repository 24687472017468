.calendly-container {
  width: 100%;
  height: 700px;
  z-index: 1000;
  margin-top: 180px;
}

@media (min-width: 576px) {
  .calendly-container {
    margin-top: 100px;
  }
}
