.Video {}

.video-container {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.play-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

