.popper-container {
  z-index: 99;
}

.dropdown-items-container {
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0px 6px 20px #00000017;
}

.dropdown-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: white;
  border: 0;
  cursor: pointer;
}

.dropdown-item:hover {

}

.dropdown-item:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.lang-dropdown {
  background-color: #F59A26;
  border-color: #F59A26;
  display: flex;
  align-items: center;
}

.lang-dropdown-label {
  margin: 0;
  margin-right: 10px;
  font-size: 0.8rem;
}
