body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-size: 0.8rem !important;
  padding: 5px 15px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer !important;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.main-rounded-cta {
  background-color: #F59A26 !important;
  border-color: #F59A26 !important;
  cursor: pointer !important;
  z-index: 9999;
  line-height: 45px;
  border-radius: 35px;
  border: 1px solid white;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  font-size: 1.2rem !important;
  text-transform: uppercase;
  color: white;
  padding: 10px 20px;
  min-width: 275px;
}

a.main-rounded-cta {
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
}

.main-rounded-cta:hover {
  background-color: #f7b35c !important;
}

