.exit-button {
  border: 0px;
  background-color: transparent;
  padding: 10px;
}

.exit-office-block {
  width: 35vw;
  border: 0;
}

.request-a-call-header-block {
  height: 140px;
  background-color: white;
  border-radius: 20px 20px 0 0;
  border: 0;
}

.request-a-call-header-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  height: 100%;
  justify-content: flex-end;
  background-color: rgba(112, 112, 112, 0.30);
  border-radius: 20px 20px 0 0;
  border: 0;
}

.request-a-call-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.close-modal-button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.close-modal-button:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.request-a-call-header-text {
  text-transform: capitalize;
  color: #0E3059;
  font-size: 35px;
  font-weight: 500;
  margin: 10px 0;
}

.request-a-call-body {
  height: 120px;
  background-color: white;
  border-radius: 0 0 20px 20px;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.request-a-call-body-text {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  text-align: left;
}

.phone-number-input-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.phone-number-input {
  width: 64%;
  height: 35px;
  padding: 0 15px;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
}

.send-button {
  width: 25%;
  border: 0;
  border-radius: 5px;
  background-color: #F59A26;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.send-button:active {
  transform: translateY(1px);
}
