.overlay {
  width: 100%;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  top: -175px;
  left: 0;
  right: 0;
  bottom: 0;
}

.question {
  color: white;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 1.7rem;
  text-align: center;
}

.answers-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 6px 0;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  color: white;
  border-radius: 35px;
}

.answer-button:hover {
  color: #123D71;
  background-color: rgba(255, 255, 255, 1);
}

.answer-button-text {
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-left: 5px;
  font-weight: 700;
}

.answers-separator {
  color: white;
  font-weight: 700;
  font-size: 1rem;
  margin: 15px 0;
  text-transform: uppercase;
}

.yes-btn {
  background-color: #40C075;
}

.no-btn {
  background-color: #DA3336;
}

.exit-block {
  border-radius: 64px;
  background-color: #123D71;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px;
  padding: 30px 0;
  max-width: 900px;
}

.exit-block-text {
  text-align: center;
  color: white;
  font-size: 1rem;
  max-width: 900px;
  padding: 20px;
  line-height: 1.5rem;
}

.bluemina-anchor {
  cursor: pointer;
}

.bluemina-url-button {
  display: flex;
  align-items: center;
  background-color: #0F325D;
  border-radius: 40px;
  border: 0;
  padding: 40px;
  height: 120px;
  cursor: pointer;
}

.bluemina-url-button-text {
  color: #D5E6FA;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: left;
  padding: 0 10px;
}

@media (min-width: 340px) {
  .answer-button {
    width: 200px;
    padding: 0px 5px;
  }
  .answers-container {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .answers-separator {
    margin: 0 70px;
  }
}

@media (min-width: 576px) {
  .answer-button {
    width: 300px;
  }
}




